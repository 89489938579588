// TODO: Refactor this, it's dumb
import React from 'react'
import stringify from 'qs/lib/stringify'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import CustomLink from 'components/Link/CustomLink'

import style from './Pagination.module.scss'

import { maxPaginationLinks } from 'config/Pagination'

import { scrollToTop } from 'utils/scroll'
import urlToSearchState from 'utils/search/urlToSearchState'

import PreviousIcon from 'assets/icons/icon-paginationPrevious.svg'
import NextIcon from 'assets/icons/icon-paginationNext.svg'
import ElipsisIcon from 'assets/icons/icon-paginationElipsis.svg'

type PaginationProps = {
  page: number
  itemsPerPage: number
  totalPages: number
  totalItems: number
  refine?: (...args: any[]) => void
  createURL?: (page: number) => any
  onPaginate?: (...args: any[]) => void
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  itemsPerPage,
  totalPages,
  totalItems,
  refine,
  createURL,
  onPaginate,
}) => {
  const router = useAugmentedRouter()
  const params = urlToSearchState(router.asPath)
  let firstSeparatorAdded = false
  let secondSeparatorAdded = false

  const isActivePage = (page: number) => {
    if (params.page && +params.page === page) {
      return true
    }

    if (!params.page && page === 1) {
      return true
    }

    return false
  }

  const addUrlParam = (page: number) => {
    return {
      pathname: router.pathname,
      query: {
        ...router.query,
        page,
      },
    }
  }

  const pages = Array.from(Array(totalPages))

  for (let i = 0; i < pages.length; i++) {
    pages[i] = i + 1
  }

  const isPageInRange = (p: number) => {
    if (pages.length <= maxPaginationLinks) {
      // render all pages when they are shorter than the max length
      return true
    } else if (p === page) {
      // always render the current page
      return true
    } else if (p === 1) {
      // always render the first page
      return true
    } else if (p === pages.length) {
      // always render the last page
      return true
    } else if (p >= page - 2 && p <= page + 2) {
      // render 2 pages before, and 2 pages after current page
      return true
    }

    return false
  }

  const shouldRenderFirstSeparator = (p: number) => {
    if (firstSeparatorAdded) {
      return false
    }

    if (p < page) {
      return true
    }

    return false
  }

  const shouldRenderSecondSeparator = (p: number) => {
    if (secondSeparatorAdded) {
      return false
    }

    if (p === pages.length - 1) {
      return true
    }

    return false
  }

  const onNavLinkClick = (e: any, pageTo: number) => {
    e.preventDefault()
    scrollToTop()
    onPaginate?.()
    refine?.(pageTo)
  }

  return (
    <div className={style.pagination}>
      <ul className={style.pages}>
        {page && page > 1 && !refine ? (
          <li>
            <CustomLink
              href={addUrlParam(page - 1)}
              onClick={() => {
                scrollToTop()
                onPaginate?.()
              }}
              shallow={true}
              title={`Page ${page - 1}`}
            >
              <PreviousIcon className={style.paginationArrow} />
            </CustomLink>
          </li>
        ) : page && page > 1 && refine ? (
          <li>
            <CustomLink
              href={router.pathname + createURL?.(page - 1)}
              onClick={(e) => {
                onNavLinkClick(e, page - 1)
              }}
              shallow={true}
              title={`Page ${page - 1}`}
            >
              <PreviousIcon className={style.paginationArrow} />
            </CustomLink>
          </li>
        ) : null}
        {pages.map((p, i) => {
          if (totalItems <= itemsPerPage) {
            return (
              <li className={style.disabled} key={i}>
                <div>{p}</div>
              </li>
            )
          } else if (isPageInRange(p)) {
            return !refine ? (
              <li className={isActivePage(p) ? style.active : ''} key={i}>
                <CustomLink
                  href={addUrlParam(p)}
                  onClick={() => {
                    scrollToTop()
                    onPaginate?.()
                  }}
                  shallow={true}
                  title={`Page ${p}`}
                >
                  {p}
                </CustomLink>
              </li>
            ) : (
              <li className={isActivePage(p) ? style.active : ''} key={i}>
                <CustomLink
                  href={router.pathname + createURL?.(p)}
                  onClick={(e) => onNavLinkClick(e, p)}
                  shallow={true}
                  title={`Page ${p}`}
                >
                  {p}
                </CustomLink>
              </li>
            )
          } else {
            if (shouldRenderFirstSeparator(p)) {
              firstSeparatorAdded = true
              return (
                <li className={style.elipsis} key={i}>
                  <ElipsisIcon />
                </li>
              )
            } else if (shouldRenderSecondSeparator(p)) {
              secondSeparatorAdded = true
              return (
                <li className={style.elipsis} key={i}>
                  <ElipsisIcon />
                </li>
              )
            }
          }

          return false
        })}
        {page < totalPages && !refine ? (
          <li>
            <CustomLink
              href={addUrlParam(page + 1)}
              onClick={() => {
                scrollToTop()
                onPaginate?.()
              }}
              shallow={true}
              title={`Page ${page + 1}`}
            >
              <NextIcon className={style.paginationArrow} />
            </CustomLink>
          </li>
        ) : page < totalPages && refine ? (
          <li>
            <CustomLink
              href={router.pathname + createURL?.(page + 1)}
              onClick={(e) => {
                onNavLinkClick(e, page + 1)
              }}
              shallow={true}
              title={`Page ${page + 1}`}
            >
              <NextIcon className={style.paginationArrow} />
            </CustomLink>
          </li>
        ) : null}
      </ul>
    </div>
  )
}

export default Pagination
