import React from 'react'
import { signOut } from 'next-auth/react'
import { useRouter } from 'next/router'

import { useSelect } from 'store/index'

import CustomLink from 'components/Link/CustomLink'

import { pushToDataLayer } from 'utils/Gtm'
import { isOnServerSide } from 'utils/validations'

type GuestRegisterButtonProps = {
  id?: string
  className?: string
  displayText?: string
}

export const GuestRegisterButton: React.FC<GuestRegisterButtonProps> = ({
  id,
  className,
  displayText = 'Create Account',
}) => {
  const router = useRouter()
  const loginPromptModalOrigin = useSelect(
    (state) => state.uiState.loginPromptModalOrigin,
  )
  const selectedListings = useSelect(
    (state) => state.favorites.selectedListings,
  )
  const firstFavoriteId = useSelect((state) => state.favorites.firstFavoriteId)

  const handleGuestAuthClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!isOnServerSide) {
      // Used for displayOption in GTM createAccountSuccess event
      localStorage.setItem('loginPromptModalOrigin', loginPromptModalOrigin)
      // Save first listing clicked to favorite it after sign up
      if (firstFavoriteId) {
        localStorage.setItem('firstFavoriteId', firstFavoriteId)
      }
    }
    // GTM tracking - click create account link
    pushToDataLayer('initiateAccountCreation', {
      displayOption: loginPromptModalOrigin,
      favoriteCount:
        loginPromptModalOrigin === 'Favorites Page'
          ? selectedListings.length
          : !selectedListings.length && firstFavoriteId
          ? 1
          : selectedListings.length,
    })

    // Make signOut call to update nextAuth 'callback-url' cookie
    // https://next-auth.js.org/v3/configuration/options#cookies
    signOut({ redirect: false })
    router.push(
      `${process.env.NEXT_PUBLIC_SALESFORCE_REGISTRATION_URL}?redirect=true`,
    )
  }

  return (
    <CustomLink
      aria-labelledby={`link, ${displayText}`}
      className={className}
      href="#"
      id={id}
      onClick={handleGuestAuthClick}
    >
      {displayText}
    </CustomLink>
  )
}

export default GuestRegisterButton
