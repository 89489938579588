import React from 'react'

import { useSelect } from 'store/index'

import style from './Pagination.module.scss'

type OverviewProps = {
  className: string
  totalItems: number
  page: number
  itemsPerPage: number
}

const Overview: React.FC<OverviewProps> = ({
  className,
  totalItems,
  page,
  itemsPerPage,
}) => {
  const width = useSelect((state) => state.uiState.width)

  return (
    <div className={`${style.overview} ${className && className}`}>
      {
        <b>
          {totalItems ? page * itemsPerPage - (itemsPerPage - 1) : '0'} -{' '}
          {page * itemsPerPage <= totalItems ? page * itemsPerPage : totalItems}
        </b>
      }
      <span data-testid="totalProperty">&nbsp;of {totalItems}</span>
      {width > 600 && <span>&nbsp;properties</span>}
    </div>
  )
}

export default Overview
