import React from 'react'

import style from './BarLoader.module.scss'

export type BarLoaderProps = {
  height: string | number
  marginBottom?: number
  width: string | number
  numberOfBars?: number
  useTransparent?: boolean
}

const BarLoader: React.FC<BarLoaderProps> = ({
  height,
  marginBottom,
  width,
  numberOfBars,
  useTransparent,
}) => {
  const barJsx = (
    <div
      className={`${style.barLoader} ${
        useTransparent ? style.transparent : ''
      }`}
      style={{
        height,
        marginBottom,
        width: width,
      }}
    />
  )

  return numberOfBars ? (
    <>
      {Array(numberOfBars)
        .fill(barJsx)
        .map((bar, i) => React.cloneElement(bar, { key: i }))}
    </>
  ) : (
    barJsx
  )
}

export default BarLoader
