import React from 'react'

import style from './ResultGridLoader.module.scss'
import ResultLoader from './ResultLoader'

type ResultGridLoaderProps = { gridLength: number }

const ResultGridLoader: React.FC<ResultGridLoaderProps> = ({ gridLength }) => (
  <div className={style.resultGridLoader}>
    {[...Array(gridLength)].map((_, i) => (
      <ResultLoader key={i} />
    ))}
  </div>
)

export default React.memo(ResultGridLoader)
