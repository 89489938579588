import React from 'react'

import style from './ResultLoader.module.scss'
import BarLoader from './BarLoader'

const ResultLoader: React.FC = () => {
  return (
    <div className={style.resultLoader}>
      <div className={style.resultImgContainer}>
        <div className={style.resultImg} />
      </div>
      <div className={style.resultContent}>
        <div className={style.resultContentTop}>
          <BarLoader height={16} marginBottom={4} width={16} />
          <BarLoader height={16} marginBottom={4} width={93} />
          <BarLoader height={16} marginBottom={4} width={395} />
          <BarLoader height={16} marginBottom={14} width={156} />
          <BarLoader height={16} width={182} />
        </div>
        <div className={style.resultContentBottom}>
          <BarLoader height={16} width={120} />
          <BarLoader height={16} width={141} />
        </div>
      </div>
    </div>
  )
}

export default ResultLoader
