import React, { useEffect } from 'react'

import { useSelect, useDispatch } from 'store/index'

import { setLoginPromptModalOrigin } from 'reducers/uiState'
import { setSaveAsGuest, trackFavoriteEvent } from 'reducers/favorites'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import GuestLogin from 'components/Guest/GuestLogin'
import GuestRegisterButton from 'components/Guest/GuestRegisterButton'

import style from './FavoritesLogin.module.scss'

import { pushToDataLayer } from 'utils/Gtm'

import HeartEmpty from 'assets/icons/icon-heartEmpty.svg'

type FavoritesLoginPromptProps = { isModal: boolean }

const FavoritesLoginPrompt: React.FC<FavoritesLoginPromptProps> = ({
  isModal,
}) => {
  const router = useAugmentedRouter()

  // Redux actions
  const appDispatch = useDispatch()

  // Redux selectors
  const loginPromptModalOrigin = useSelect(
    (state) => state.uiState.loginPromptModalOrigin,
  )

  const { selectedFavorite } = useSelect((state) => state.favorites)

  // GTM tracking - view create account prompt (favorites page without saveAsGuest)
  useEffect(() => {
    if (!isModal) {
      appDispatch(setLoginPromptModalOrigin('Favorites Page'))

      if (loginPromptModalOrigin === 'Favorites Page') {
        pushToDataLayer('createAccountPrompt', {
          displayOption: loginPromptModalOrigin,
        })
      }
    }
  }, [appDispatch, isModal, loginPromptModalOrigin])

  return (
    <div className={style.loginPromptMain}>
      <HeartEmpty className={style.emptyHeart} title="" />
      <h4
        className={`${style.loginPromptHeading} ${
          !isModal ? style.longText : ''
        }`}
      >
        Create an Evolve account to save your favorite homes
      </h4>
      <GuestRegisterButton
        className={`${style.createAccountBtn} btn btn-primary`}
        id="favorites_login_guest_register"
      />
      {/* Save as guest button */}
      {isModal && (
        <button
          className={`btn btn-noStyle ${style.customLink} ${style.saveAsGuestBtn}`}
          onClick={() => {
            appDispatch(setSaveAsGuest(true))
            appDispatch(trackFavoriteEvent(selectedFavorite))
          }}
        >
          Save temporarily without an account
        </button>
      )}
      <p className={style.accountCopy}>Already have an account?</p>
      <GuestLogin
        className={`btn btn-noStyle ${style.signInBtn}`}
        displayText={{
          isLoggedIn: 'Sign out',
          isLoggedOut: 'Sign in',
        }}
        id="favorites_login_guest_auth"
      />
      {/* Start search button */}
      {!isModal && (
        <>
          <p className={style.startSearchCopy}>
            Not ready to sign up? Save homes temporarily without an account.
          </p>
          <button
            className={`btn btn-noStyle ${style.customLink}`}
            onClick={() => router.push(`/vacation-rentals/search`)}
          >
            Start your search now!
          </button>
        </>
      )}
    </div>
  )
}

export default FavoritesLoginPrompt
